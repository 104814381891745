<template>
  <div>
    <div class='DashboardFormInput'
         style='width: 100%; min-height: 345px; flex-direction: column; justify-content: flex-start; align-items: flex-end; gap: 12px; display: inline-flex'>
      <div class='DashboardInputTitle'
           style='justify-content: flex-end; align-items: center; gap: 4px; display: inline-flex'>
        <div class='Label'
             style='text-align: right; color: #6F7D91; font-size: 16px; font-family: Lama Sans; font-weight: 500; line-height: 24px; word-wrap: break-word'>
          صورة الإعلان
        </div>
      </div>
      <div class='upload_container'>
        <div class='upload_options'>
          <div class='upload_options-option'
               :class='[index==activeIndex ? "active" : ""]'
               v-for='(option,index) in options'
               :key='index'
               @click='activeIndex = index'
          >
            <div class='option_icon'>
              <component :is='option.icon' v-if='option.icon' />
              <svg v-else xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36' fill='none'>
                <path
                  d='M21 27C21 33 24 33 24 33M24 33H25.5M24 33H12M10.5 33H12M3 19.5V22.2C3 23.8802 3 24.7202 3.32698 25.362C3.6146 25.9265 4.07354 26.3854 4.63803 26.673C5.27976 27 6.11984 27 7.8 27H15M3 19.5V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H28.2C29.8802 3 30.7202 3 31.362 3.32698C31.9265 3.6146 32.3854 4.07354 32.673 4.63803C33 5.27976 33 6.11984 33 7.8V19.5M3 19.5H33M33 19.5V22.2C33 23.8802 33 24.7202 32.673 25.362C32.3854 25.9265 31.9265 26.3854 31.362 26.673C30.7202 27 29.8802 27 28.2 27H15M12 33C12 33 15 32.25 15 27'
                  stroke='#4B5D75' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
              </svg>
            </div>
            <div>
              <div class='option_size'>
                <span>{{ option.width }}</span>
                <span> x </span>
                <span>{{ option.height }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class='upload_zone'
             @dragover.prevent='onDragOver'
             @dragleave.prevent='onDragLeave'
             @drop.prevent='onDrop'
        >
          <input name='file' type='file' accept='image/*' class='file' ref='fileInput' @change='onFileSelect' />

          <div class='upload_zone-placeholder' v-if='activeScreenFiles.length===0'>
            <div class='ImagePlus' style='width: 32px; height: 32px; position: relative'>
              <svg xmlns='http://www.w3.org/2000/svg' width='32' height='33' viewBox='0 0 32 33' fill='none'>
                <path opacity='0.12'
                      d='M19.3155 23.6019L21.4878 21.4295L21.4878 21.4295C22.2799 20.6374 22.676 20.2414 23.1326 20.093C23.5343 19.9625 23.967 19.9625 24.3687 20.093C24.8253 20.2414 25.2214 20.6374 26.0134 21.4294L28.9001 24.3161C28.7864 24.7321 28.643 25.1101 28.462 25.4653C27.695 26.9706 26.4712 28.1944 24.9659 28.9614C24.9014 28.9943 24.8361 29.0259 24.77 29.0564C23.0825 29.8333 20.8454 29.8333 16.534 29.8333H16.534H15.4673C10.9869 29.8333 8.74667 29.8333 7.03538 28.9614C5.53008 28.1944 4.30624 26.9706 3.53925 25.4653C3.48215 25.3532 3.42878 25.2388 3.37891 25.1217L9.77107 18.7296C10.5526 17.948 10.9434 17.5573 11.3949 17.4083C11.7922 17.2773 12.2205 17.2741 12.6197 17.3993C13.0734 17.5415 13.4699 17.9265 14.2629 18.6964L19.3155 23.6019Z'
                      fill='#939EAC' />
                <path
                  d='M3.33464 25.1641L9.77174 18.727C10.5533 17.9454 10.944 17.5547 11.3956 17.4057C11.7929 17.2747 12.2212 17.2715 12.6204 17.3967C13.0741 17.5389 13.4706 17.9239 14.2636 18.6938L19.3161 23.5992M28.668 24.0807L26.0141 21.4268C25.222 20.6348 24.826 20.2388 24.3693 20.0904C23.9677 19.9599 23.535 19.9599 23.1333 20.0904C22.6766 20.2388 22.2806 20.6348 21.4884 21.4269C20.3427 22.5727 19.3161 23.5992 19.3161 23.5992M24.668 28.9511L19.3161 23.5992M24.0013 12.4974V8.4974M24.0013 8.4974V4.4974M24.0013 8.4974H20.0013M24.0013 8.4974H28.0013M16.0013 3.16406H15.3346H12.268C8.90765 3.16406 7.2275 3.16406 5.94403 3.81802C4.81505 4.39326 3.89717 5.31115 3.32193 6.44012C2.66797 7.72359 2.66797 9.40374 2.66797 12.7641V20.2307C2.66797 23.591 2.66797 25.2712 3.32193 26.5547C3.89717 27.6836 4.81505 28.6015 5.94403 29.1768C7.2275 29.8307 8.90765 29.8307 12.268 29.8307H19.7346C23.095 29.8307 24.7751 29.8307 26.0586 29.1768C27.1876 28.6015 28.1054 27.6836 28.6807 26.5547C29.3346 25.2712 29.3346 23.591 29.3346 20.2307V17.1641V16.4974'
                  stroke='#939EAC' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
              </svg>
            </div>
            <div class='upload_zone-label'>
              اسحب الصورة إلى هنا أو إضغط هنا لإضافة الصورة
            </div>
          </div>

          <span v-if='activeScreenFiles.length>0'>
          <div class='upload_zone_clickable' @click='selectFiles'></div>
          <div class='images_list'>
            <div class='image_container' style='z-index:1' v-for='(image,index) in activeScreenFiles' :key='index'>
              <span class='delete' @click='()=>removeFile(image.uuid)'>&times;</span>
              <div class='image_url'>
                <img :src='image.url' :alt='image.name' />
              </div>

            </div>
          </div>
          </span>
          <div class='upload_zone_dragging' v-if='isDragging'></div>
          <div class='upload_zone_clickable' @click='selectFiles'></div>
        </div>
      </div>
    </div>

    <cropper-modal
      v-if="showCropperModal"
      :visible="showCropperModal"
      :src="cropImageSrc"
      @close="onCropClose"
      @onCrop="onCrop"
      :height="cropperHeight"
      :fixed="true"
      :width="cropperWidth"
    ></cropper-modal>
  </div>
</template>

<script>


import CropperModal from '@/components/cropperModal.vue';

export default {
  components: { CropperModal },
  data: function() {
    return {
      cropImageSrc: '',
      activeIndex: 0,
      cropperHeight: 0,
      cropperWidth: 0,
      isDragging: false,
      showCropperModal: false,
      myFiles: []
    };
  },
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    activeScreenFiles() {
      return this.myFiles.filter((file) => file.screen === this.options[this.activeIndex].screen);
    }
  },
  methods: {
    selectFiles() {
      this.$refs.fileInput.click();
    },

    onFileSelect(e) {
      //get the active option index name
      const activeOption = this.options[this.activeIndex];
      const files = e.target.files;
      if (files.length === 0) return;
      for (let i = 0; i < files.length; i++) {
        //check if the file is an image
        if (!files[i].type.match('image.*')) {
          continue;
        }
        //check if the file is existed in the array
        if (!this.myFiles.some((file) => file.name === files[i].name && file.screen === activeOption.screen)) {
          const uuid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
          this.cropperWidth = activeOption.width;
          this.cropperHeight = activeOption.height;
          this.cropImageSrc = URL.createObjectURL(files[i]);
          this.showCropperModal = true;
          // this.myFiles.push({
          //   uuid: uuid,
          //   screen: activeOption.screen,
          //   name: files[i].name,
          //   size: files[i].size,
          //   type: files[i].type,
          //   file: files[i],
          //   url: URL.createObjectURL(files[i])
          // });

        }
      }
    },

    removeFile(uuid) {
      this.myFiles = this.myFiles.filter((file) => file.uuid !== uuid);
    },

    onDragOver(e) {
      e.preventDefault();
      this.isDragging = true;
      e.dataTransfer.dropEffect = 'copy';
    },

    onDragLeave(e) {
      e.preventDefault();
      this.isDragging = false;
    },

    onDrop(e) {
      e.preventDefault();
      const activeOption = this.options[this.activeIndex];

      this.isDragging = false;
      const files = e.dataTransfer.files;
      if (files.length === 0) return;
      for (let i = 0; i < files.length; i++) {
        //check if the file is an image
        if (!files[i].type.match('image.*')) {
          continue;
        }
        //check if the file is existed in the array
        if (!this.myFiles.some((file) => file.name === files[i].name && file.screen === activeOption.screen)) {
          const uuid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
          this.cropperWidth = activeOption.width;
          this.cropperHeight = activeOption.height;
          this.cropImageSrc = URL.createObjectURL(files[i]);
          this.showCropperModal = true;
          // this.myFiles.push({
          //   uuid: uuid,
          //   screen: activeOption.screen,
          //   name: files[i].name,
          //   size: files[i].size,
          //   type: files[i].type,
          //   file: files[i],
          //   url: URL.createObjectURL(files[i])
          // });
        }
        break;
      }
    },



    setImage(file) {
      this.image = file;
      this.showCropperModal = true;
      this.cropImageSrc = file;
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {type: mime});
    },
    onCrop(croppedImage) {

      this.image = croppedImage;
      this.tempImage = this.image;
      this.showCropperModal = false;

      //convert this.image base64 to file blob
      // generate a random file name
      const fileExtension = this.image.split(';')[0].split('/')[1];
      const randomNumber = Math.floor(Math.random() * 1000000000);
      const fileName = `image-${randomNumber}.${fileExtension}`;
      const file = this.dataURLtoFile(this.image, fileName);


      const uuid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      this.myFiles.push({
        uuid: uuid,
        screen: this.options[this.activeIndex].screen,
        name: file.name,
        size: file.size,
        type: file.type,
        file: file,
        url: this.image
      });

      this.hideCropperModal();


    },
    onCropClose() {
      this.showCropperModal = false;
      this.image = this.tempImage;
      this.hideCropperModal();
    },
    unsetImage() {
      this.image = "";
      this.$emit("changed", this.image);
    },
    hideCropperModal() {
      let modal = document.querySelector(".modal-open");
      if (modal) {
        modal.style = null;
      }
    }
  }


};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang='scss' scoped>
.upload_zone_dragging {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: #F0EAFA;
  opacity: 0.5;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;

}

.upload_zone_clickable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.images_list {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
}

.image_container {
  position: relative;
  width: 160px;
  height: 160px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 12px;
  padding: 6px;
  display: flex;
  background: #FCFCFD;

  .delete {
    position: absolute;
    top: -2px;
    right: -2px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #da2323;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: #e31515;
      transform: scale(1.1);
    }
  }

  .image_url {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 6px;
    border: 1px #B7BEC8 solid;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 12px;
    display: flex;
    background: #FCFCFD;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .image_name {
    color: #4B5D75;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  .image_size {
    color: #939EAC;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    word-wrap: break-word;
  }
}

input.file {
  display: none;
}

.upload_container {
  padding: 24px;
  background: #FCFCFD;
  border-radius: 16px;
  border: 1px #B7BEC8 solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 12px;
  display: flex;
  width: 100%;
}


.upload_options {
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  flex-wrap: wrap;

  &-option {
    padding: 16px 42px;
    background: #EDEFF1;
    border-radius: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    display: inline-flex;
    border: 1px transparent solid;
    cursor: pointer;

    &.active {
      background: #F0EAFA;
      border-radius: 16px;
      border: 1px #652ECE solid;

      .option_size {
        color: #652ECE
      }
    }
  }

}

.option_size {
  color: #4B5D75;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.option_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  width: 36px;
  height: 36px;
}


.upload_zone {
  min-height: 196px;
  padding: 18px 16px;
  border-radius: 16px;
  border: 1px #B7BEC8 dotted;
  align-items: center;
  width: 100%;
  gap: 8px;
  display: flex;
  position: relative;

  &-placeholder {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &-label {
    color: #939EAC;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    word-wrap: break-word;
  }
}

</style>
